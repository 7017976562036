.animate-fade-in {
    animation: fadeIn 1s ease;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

// cursor not allowed
.not-allowed:hover {
    cursor: not-allowed;
}

html,
body {
    scroll-behavior: smooth;
}

.red-border {
    border: 1px solid red;
}

.boxShadow {
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    border-radius: .125em;
}

.entry-background {
    background-size: 100% 100%;
    height: 100vh;
    background-blend-mode: hard-light;
    background-color: rgba(51, 51, 201, .2);
}

.scale-up {
    transition: .2s ease;
}

.scale-up:hover {
    transform: scale(1.02);
    transition: .2s ease;
}

.reset-btn:hover {
    transform: rotateZ(30deg);
    transition: .2s ease;
}

.reset-btn {
    transition: .2s ease;
}

.cursor-pointer {
    cursor: pointer;
}

.filter-by-time-width {
    width: 400px;
}

.time_select__control {
    background-color: black !important;
    color: white !important;
    border-radius: .5em !important;
    font-weight: 600;
}

.time_select__menu {
    background-color: black !important;
    color: white !important;
}

.time_select__option:hover,
.time_select__option:active,
.time_select__option:focus-visible,
.time_select__option:focus,
.time_select__option:focus-within {
    background-color: grey;
}

.time_select__option {
    background-color: grey;
}

.breadcrumb {
    background-color: transparent;
}

.breadcrumb-item+.breadcrumb-item:before {
    content: ">" !important;
}

.breadcrumb-item.active {
    color: rgba(31, 29, 29, 0.685);
    font-weight: bold;
}




.accordion-item{
border:none ;
}
.accordion-button:not(.collapsed){
    box-shadow: none;
    border: none;
}

.accordion-button:not(.collapsed){
    box-shadow: none;
    border: none;
}


// REACT QUILL STYLE
.ql-container.ql-snow{
    height:50em;
}

.height-100{
    height:100px;
    background-color: red;
}

// MIS DASHBOARD SELECT STYLES

.mis_dashboard_select__control {
    background-color: black !important;
    color: white !important;
    // border-radius: .5em !important;
    font-weight: 600;
    // border:1px solid #4a507366 !important;
    border:1px solid transparent !important;
}

.mis_dashboard_select__menu {
    background-color: black !important;
    color: white !important;
    border:1px solid grey !important;
}

.mis_dashboard_select__option:hover,
.mis_dashboard_select__option:active,
.mis_dashboard_select__option:focus-visible,
.mis_dashboard_select__option:focus,
.mis_dashboard_select__option:focus-within {
    background-color: grey;
}

.mis_dashboard_select__option {
    background-color: black;
}

// react bootstrap form select for mis dashboard
.mis-dashboard-select{
    color:white !important;
    background-color: black !important;
    border:1px solid transparent !important;
    margin-block:.375em;
    cursor:pointer;
    // width: auto !important;
}
// .mis-dashboard-select option{
//     background-color: red !important;
// }

.nav-link{
    padding-inline:.75rem !important;
}

.nav-profile{
    padding-inline: 0px !important;
}

.z-index-highest{
    z-index: 3000;
}

.react-select-img{
    filter:invert(100%);
    height:30px;
    width: 30px;
    margin-inline-end:10px;
}

.language-bash, .hljs-attr, .language-json{
    color:white !important;
}

.email-content > div {
    width: 100% !important;
    font-size: 14px;
}
.email-content .form-label {
    color: #7e7e7e;
}
.email-content .quill {
    background: rgba(247, 247, 250, 1);
}
.email-content .ql-container.ql-snow, 
.email-content .ql-toolbar.ql-snow {
    border: none
}
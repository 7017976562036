.dashboard-tabs-container{
    margin-inline:1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid #E9E9E9;;
    
}
.dashboard-tabs{
    display:flex;
    flex-direction: column;
}

.dashboard-tabs h4{
    font-size: 16px;
    // line-height: 30px;
}

.dashboard-custom-filter {
    display:flex;
    // justify-content: flex-end;
    align-items: center;
  max-height: "80px";
  color: rgba(126, 126, 126, 1);
  font-weight: 600;
  font-size: 14px;
  transition: border 0.08s ease-in-out;
  border:1px solid transparent;
  border-radius: 8px;
  padding:3px 10px;
cursor: pointer;
  &:hover{
    transition-delay: 0.08s;
    border:1px solid rgba(126, 126, 126, .7);
  }
}

.rts-badge-container{
  display:flex;
  flex-direction: column;
  // gap:.5em;
}
.rts-badge-container .total-requested-key{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;

/* Secondary text */
color: #7E7E7E;
}

.rts-badge-container .total-requested-value{

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
color: #26292D;


}

.rts-percentage{
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

/* Secondary text */
color: #7E7E7E;

}
.rts-slice-name{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
/* identical to box height */

/* Secondary text */
color: #7E7E7E;
}

.box{
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-block-start:5px;
}
.pending-box{
background: rgb(255,233,134);
}

.divider-margin-accordions{
  margin-block:10px;
}

.dashboard-heading{
  font-family: Inter;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(38, 41, 45, 1);
  
}

.send-modal .modal-content {
    overflow: hidden !important;
}
.send-modal .modal-body {
    height: 520px;
    overflow: hidden !important;
}
.scroll-wrapper::-webkit-scrollbar {display:none;}
.scroll-wrapper {
    height: 100%;
    width: 100%;
    overflow: auto !important;
}
.send-modal .modal-footer {
    box-shadow: none;
    border: none;
}
.send-modal .modal-title {
    margin: 0 auto;
}
.send-modal .btn-close {
    margin: 0;
    width: 0em;
    height: 0em;
}
.send-modal .form-control,
.send-modal .form-select {
    background-color: #f9f9f9;
    color: black;
    border: none;
    box-shadow: none;
}
.stepper {
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 15px;
}

.next-btn {
    border: 0;
    background: rgb(115, 115, 219);
    &:hover,
    &:active,
    &:focus-visible,
    &:focus,
    &:disabled {
        background: rgb(115, 115, 219) !important;
    }
}
.text-btn {
    box-shadow: none;
    color: black;
    border: 0;
    &:hover,
    &:active,
    &:focus-visible,
    &:focus,
    &:disabled {
        color: black !important;
        border: 0;
        box-shadow: none;
    }
}
.send-modal .modal-title {
    font-size: 1.1rem;
}
.send-modal .modal-body {
    padding: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
}
.send-modal .select__control {
    border: none;
    background-color: #f9f9f9;
}
.send-modal .select__indicator-separator {
    border: none;
    background-color: #f9f9f9;
}
.send-modal .select__dropdown-indicator {
    border: none;
    color: black !important;
}
.send-modal .select__placeholder {
    color: black !important;
}

.btn-close {
    margin-right: 7px;
    width: 1em;
    height: 1em;
    &:active, &:focus, &:focus-visible {
        border: 0;
        box-shadow: none;
    }
}
.back-icon-btn {
    padding: 0 !important;
    &:hover,
    &:active,
    &:focus-visible,
    &:focus,
    &:disabled {
        background: transparent !important;
    }
}
.sendicon {
    width: 26px !important;
    height: 26px !important;
}
.send-dropdown.dropdown-menu.show {
    right: auto !important;
    left: 4rem !important;
}
.sendMenu .nav-item {
    width: 100% !important;
}
.sendMenu .media-body {
    width: 100% !important;
}
.sendMenu .fw-bold {
    font-weight: 500 !important;
}
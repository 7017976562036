.entry-type-container {
    // background-color: red;
    opacity: .8;
}

.entry-tab {
    cursor: pointer;
    // color:red
}
.border-below {
    border-bottom: px solid #E9E9E9;
}

.entry-type-seperator {
    font-weight: 100;
    // color:  red;
}
.vector{
    border-bottom: 10px solid #F1F1F1;
    margin-bottom: 12px;
}

.user-entry__active-tab {
    color: rgba(41, 41, 41, 1)  ;
    border-bottom: 2px solid  rgba(17, 21, 24, 1);
    padding-bottom:.75rem;
    margin-bottom:0;
}
.user-entry__inactive-tab {
    color:  rgba(126, 126, 126, 1)  ;
    padding-bottom:.5rem;
    margin-bottom:0;
}

// .entry-animation {
   
//     animation: appear 1s;
// }

// @keyframes appear {
//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// }
.fontweight-menu{
    font-weight: 500 !important;
}
.user-icon{
    height: 45px !important;
    width: 45px !important;
}
.sidebar-nav-item-text {
    display: inline-block;
    font-weight: 500;
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px;
}
.menu-user-bar {
    position: fixed;
    bottom: 24px;
    width: 100%;
  }
  .user-text {
    display: inline-block;
    font-weight: 600;
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px; 
}

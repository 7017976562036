.ag-root-wrapper {
  border: none !important;
}
.ag-header-cell {
  background-color: white !important;
  border-left: 5px transparent solid !important;
  color: rgba(126, 126, 126, 1) !important;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  background-color: transparent !important;
  background-color: transparent !important;
}

.ag-theme-alpine .ag-row {
  border-bottom: 1px solid rgba(233, 233, 233, 1) !important;
  border-left: none !important;
}

.ag-theme-alpine .ag-header-row {
  border: none !important;
  background-color: white !important;
}
.ag-theme-alpine .ag-footer-row {
  border: none !important;
}

.ag-header-viewport {
  background-color: white !important;
}

.capitalize {
  text-transform: capitalize;
}

.recipient_status_approved,
.recipient_status_pending,
.recipient_status_rejected {
  border-radius: 10px;
  padding-inline: 10px;
  padding-block: 1.5px;
  margin-block-start: 7.5px;
  // margin-inline: auto;
}
.ag-row-hover {
  cursor: pointer !important;
}

.cell-border-left {
  margin-inline-start: 18px !important;
  border-left: 1px solid rgba(233, 233, 233, 1) !important;
  border-bottom:1px solid transparent !important;
}

.user-ag-grid .ag-root-wrapper{
  // border: solid 10px red !important;
}

.ag-header{
  border-bottom: solid 1px rgba(233, 233, 233, 1) !important;

}
.ag-paging-panel  {
  border-top: solid 1px rgba(233, 233, 233, 1) !important;
}
.recipient-audit-log-grid .ag-root-wrapper{
  height:100% !important;
}

.recipient-audit-log-grid .ag-row-hover{
  cursor: default !important;
}

// .recipient-audit-log-grid .ag-row  {
//   border-bottom:1px solid transparent !important;
// }
.ag-theme-alpine {
    font-family: "Nunito Sans", sans-serif !important;
}

.transparent-btn {
    transition: all 1s ease;
}

.transparent-btn:hover {
    background-color: rgba(0, 0, 0, .8);
    transition: all .2s ease;
}
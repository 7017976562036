.recipient-details-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.recipient-details-header {
  // flex-grow: 1;
  min-height: 150px;
  padding: 18px 22px 9px 22px;
}

.recipient-details-body-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 9px 22px;
}

.recipient-details-footer {
  // flex-grow: 1;
  min-height: 90px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 9px 22px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// recipient modal styles
/* Modal.css */

.recipient-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 10px;
  margin-block-end: 40px;
}

.tab-switch-container {
  display: flex;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  font-weight: 700;
  font-family: "Inter";
}

.tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: transparent;
  transition: width 0.3s ease-in-out, right 0.3s ease-in-out;
}

.tab.active {
  color: rgba(41, 41, 41, 1);
}
.tab.inactive {
  color: rgb(126, 126, 126);
}
.tab.active::after {
  width: 100%;
  left: 0;
  background-color: grey;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.recipient-details-label {
  color: rgba(126, 126, 126, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin: 0;
  margin-block-end: 8px;
}

.recipient-details-text-container {
  padding: 13.5px 12px;
  background: #f9f9f9;
  border-radius: 8px;
}
.recipient-details-select-container {
  padding: 3px 6px;
  background: #f9f9f9;
  border-radius: 8px;
}
.recipient-details-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  text-align: left;
  margin: 0;
}

.recipient-item-pair-container {
  display: flex;
  // justify-content: space-between;
  gap: 20px;
  margin-block-start: 20px;
}

.recipient-item-pair-container > div {
  width: 50%;
}

.split-line {
  border: 1px solid rgba(233, 233, 233, 1);
}

// .accordion-header,
// .accordion-body {
//   background-color: white !important;
// }

.accordion-header button {
  background-color: white !important;
}

//   Response buttons for recipient details modal

.recipient_status_approved {
  color: rgba(7, 114, 37, 1);
  background: rgba(233, 251, 238, 1);
}

.recipient_status_pending {
  color: rgba(199, 118, 23, 1);
  background: rgba(248, 238, 227, 1);
}

.recipient_status_rejected {
  color: rgba(245, 82, 82, 1);
  background: rgba(248, 235, 235, 1);
}


.recipient-modal-response-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  flex-grow: 100;
  align-items: flex-end;
}
.reject {
  padding: 11px 12px;

  background: #f7f7fa;
  border-radius: 8px;
  cursor: pointer;
}
.reject-text {
  //styleName: 12-700;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: rgba(189, 61, 68, 1);
  margin: 0;
}
.approve {
  padding: 11px 12px;
  background: #7373db;
  border-radius: 8px;
  cursor: pointer;
}
.approve-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin: 0;
  color: rgba(255, 255, 255, 1);
}

.divider-margin {
  margin-block-start: 20px;
}

.recipient-detail-accordion-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: rgba(17, 21, 24, 1);
}

.mui-input,
.mui-input:focus {
  border: 1px solid transparent !important;
  background-color: transparent !important;
  height: 40px !important;
}

.css-18liukp-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: rgba(115, 115, 219, 1) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// Removes margin for MUI text field
.css-1b0ve20 > :not(style) {
  margin-inline: 0 !important;
}

///Audit log tab text

.audit-log-container{
padding: 16px;
background: rgba(237, 237, 241, 0.27);
border-radius: 8px;
margin-block:14px;
// border:2px solid red;
margin-bottom:50px;

}
.audit-log-text {
  /* Mon (12:00 PM to 02:00 PM, 03:00 PM to 08:00 PM)Tue, Fri (12:00 AM to 02:00 PM) */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-block : 4px;
  color: #707275;
}

.recipient-audit-log-grid{
  height:100%;
}
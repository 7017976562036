/*
 * Sidebar
*/
.sidebar {
	display: block;
	position: relative !important;
	top: 0;
	bottom: 0;
	left: 0;
	// z-index: 10000 !important;
	max-height: 100%;
	overflow-y: auto;
	overflow: visible !important;
	// width: 100%;
	@include transition(max-width .3s);

	@include media-breakpoint-up(md) {
		width: 100%;
		// max-width: 300px;
	}
	@media (max-width: 768px) {
		.sidebar {
		  position: fixed;
		  
		}
	  }

	.nav {
		white-space: nowrap;
	}

	.nav-item {
		border: transparent;

		&.active {
			&>.nav-link {
				color: $light;
				background-color: #EBEBF3;
				border: none;
				margin-left: "12px";
				margin-right: "12px";
				.sidebar-nav-item-text {
					font-family: "Inter";
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
				}
			}
		}

		.nav-link {
			color: $light;
			border: none;
			background-color: transparent;
		}

		margin-bottom: .2rem;
	}
	.nav-item-collapsed {
		border: transparent;

		&.active {
			&>.nav-link {
				color: $light;
				background-color: #EBEBF3;
				border: none;
				width: 50px;
				.sidebar-nav-item-text {
					font-family: "Inter";
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
				}
			}
		}

		.nav-link {
			color: $light;
			border: none;
		}

		margin-bottom: .2rem;
	}

	.nav-link+.multi-level {
		margin-top: .2rem;
	}

	.nav-link {
		font-size: $font-size-base;
		vertical-align: middle;
		padding: 0.55rem 0.75rem;
		@include border-radius($border-radius);

		.sidebar-icon {
			margin-right: .5rem;
			color: $white;

			span {
				min-width: 22px;
			}

			svg {
				min-width: 22px;
			}

			&.svg-icon {
				margin-right: 14px;
			}
		}

		.link-arrow {
			font-size: $font-size-sm;
		}

	}

	.nav-link.active {
		color: $primary;
	}

	.multi-level {
		.nav-link {
			padding-left: 45px;
		}
	}

	.sidebar-text,
	.link-arrow,
	.badge,
	.notification-count {
		opacity: 1;
		@include transition(opacity .3s);
	}

	@include media-breakpoint-up(md) {
		.sidebar-text-contracted {
			display: none;
			@include transition(opacity .3s);
		}

		&.contracted {

			.sidebar-text,
			.link-arrow,
			.badge {
				opacity: 0;
			}

			.notification-count {
				opacity: 1;
				position: absolute;
				top: 0;
				left: 35px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				font-size: .7rem;
				padding: .2rem;
			}

			.nav-item {
				position: relative;
				width: 47px;

				.nav-link {
					&:hover {
						background-color: $primary;
					}
				}
			}

			.sidebar-text-contracted {
				display: inline;
			}

			.multi-level .nav-link {
				padding-left: 17px;
			}

			.nav-item {
				white-space: nowrap;
			}

			.sidebar-icon {
				text-align: center;
			}


			@include media-breakpoint-up(md) {
				&+.content {
					margin-left: 95px;

				}
			}

			max-width: 95px;
		}
	}
}

.sidebar-inner {
	position: relative;
	// overflow-y: hidden;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;

	.accordion-button {
		&::after {
			background-image: none;
			// content: '\f054';
			// content: '\1F86A';
			// content: url("../../../assets/right_icon.svg");
			// content: none;
			font-family: $font-awesome-5;
			font-size: $font-size-sm;
			color: 	#7E7E7E;
			font-weight: 900;
			height: 1.25rem;
			width: .5rem;
			// width: 4.37px;
			// height: 7.43px;
			// top: 50.28px;
			// left: 245.62px;
		}

		&:not(.collapsed) {
			background-color: transparent;

			&::after {
				transform: rotate(90deg);
				transition: all .2s ease;
			}
		}
	}

	.accordion {
		padding-top: .25rem;
	}

	.accordion-body {
		padding: .5rem 0 0 0;
	}

	.accordion-collapse {
		border: none;
	}
}

.sidebar-heading {
	font-size: .75rem;
	text-transform: uppercase;
}

.user-card {
	border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
	.sidebar {
		width: 100%;
	}
}

.content {

	// overflow: visible;
	padding: 0 $spacer 0 $spacer;
	// padding: 0 0 0 $spacer;
	// width: 100px !important;
	@include transition(all .3s);

	@include media-breakpoint-up(md) {
		// margin-left: 50px;
	}
}

.sidebar-toggle {
	font-size: 1.3rem;

	&:hover,
	&:focus {
		outline: none;
		box-shadow: none;
		background: $gray-400;
	}

}

.sidebar-transition-enter {
	opacity: 0;
}

.sidebar-transition-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}

.sidebar-transition-exit {
	opacity: 1;
}

.sidebar-transition-exit-active {
	opacity: 0;
	transition: opacity 200ms;
}

.text-expanded {
	position: absolute;
	left: 100%;
	border-radius: 0.375rem;
	padding-left: 0.5rem; 
	padding-right: 0.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	margin-left: 1.5rem;
	font-size: 0.875rem;
	visibility: hidden;
	opacity: 0.2;
	transition: all;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}
.group:hover{
	visibility: visible;
	opacity: 100;

}
.hover-title {
	visibility: hidden;
	// position: relative;
	// position: absolute;
	// left: 100%;
	// padding-left: 0.5rem;
  	// padding-right: 0.5rem;
	// padding-top: 0.25rem;
	// padding-bottom: 0.25rem;
	// margin-left: 1.5rem;
	// opacity: 0.2;
	// transform: translateX(-3px);
	transition-property: all;
  	transition-duration: 20s; 
  	transition-timing-function: ease; 
  }
  
  .navbar-sideicon:hover .hover-title {
	visibility: visible;
	position: relative;
	z-index: 1000;
	top: 0;
	opacity: 1;
	transform: translateX(0);
  }
  .accordion-button-hover:hover .hover-title{
	visibility: visible;
	opacity: 1;
	transform: translateX(0);

  }
  .content {
	overflow:auto;
	padding: 0 16px; /* Adjust as needed */
	transition: padding-left .3s;
  }
  
//   @media (min-width: 768px) { /* Adjust the breakpoint as needed */
// 	.content {
// 	  padding-left: 300px; /* Width of the sidebar */
// 	}
//   }
.accordion-button-collapsed {
	&::after {
		background-image: none;
		// content: '\f054';
		// content: '\1F86A';
		content: "" !important;
		// content: none;
		font-family: $font-awesome-5;
		font-size: $font-size-sm;
		color: 	#7E7E7E;
		font-weight: 900;
		height: 1.25rem;
		width: .5rem;
		// width: 4.37px;
		// height: 7.43px;
		// top: 50.28px;
		// left: 245.62px;
	}

	&:not(.collapsed) {
		background-color: transparent;

		&::after {
			transform: rotate(90deg);
			transition: all .2s ease;
		}
	}
}
// .css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
// 	overflow-y: visible !important;
// 	overflow-x: visible !important;
// }
// .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
// 	background-color: transparent !important;
// }
// .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
// 	background-color: #F7F7FA !important;
// }
.accordion-button {
	&::after {
		background-image: none;
		// content: '\f054';
		// content: '\1F86A';
		content: url("../../../assets/right_icon.svg");
		// content: none;
		font-family: $font-awesome-5;
		font-size: $font-size-sm;
		color: 	#7E7E7E;
		font-weight: 900;
		height: 1.25rem;
		width: .5rem;
		// width: 4.37px;
		// height: 7.43px;
		// top: 50.28px;
		// left: 245.62px;
	}

	&:not(.collapsed) {
		background-color: transparent;

		&::after {
			transform: rotate(90deg);
			transition: all .2s ease;
		}
	}
}

.accordion {
	padding-top: .25rem;
}

.accordion-body {
	padding: .5rem 0 0 0;
}

.accordion-collapse {
	border: none;
}
.accordion-button::after {
	background-image: none;
}
.accordion-button:not(.collapsed)::after {
	background-image: none;
}
@media (min-width: 600px) {
	.css-2dum1v-MuiDrawer-docked {
	  & .MuiDrawer-paper {
		width: calc(74px + 1px) !important;
	  }
	}
  }

  
  
  .hover-title {
	// visibility: hidden;
	width: 140px;
	background-color: #FFFFFF;
	color: #111518;
	text-align: center;
	padding: 10px 12px 10px 12px;
	border-radius: 6px;
	position: relative;
	// z-index: 1;
	// bottom: calc(100% + 10px); /* Adjust the distance of the tooltip from the bottom */
	// left: 50%;
	// margin-left: -60px;
	// opacity: 0;
	// transition: opacity 0.3s;
  }
  
  /* Arrow */
  .hover-title::before {
	content: '';
	position: absolute;
	top: 50%;
	right: 100%; /* Position the arrow to the left */
	margin-top: -10px; /* Adjust the arrow vertically */
	border-width: 10px;
	border-style: solid;
	border-color: transparent #FFFFFF transparent transparent; /* Color the arrow */
  }
 .icon-button:hover {
	background-color:  transparent !important;
 }
 
 .show-text:hover{
	overflow-x: visible !important;
	overflow-y: visible !important;
 }
 .show-label-text {
	overflow-x: visible !important;
	overflow-y: visible !important;
 }
 .main-sidebar-container-color {
	background-color:#F7F7FA !important; 
}
.drawer-open {
	width: 290px; /* Same width as your drawer */
	transition: width 0.3s ease; /* Adjust the transition duration and timing function as needed */
  }
  
  .drawer-closed {
	width: 70px; /* Width when the drawer is closed */
	transition: width 0.3s ease; /* Adjust the transition duration and timing function as needed */
  }
  